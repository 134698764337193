// Plugins
import { AES, enc } from 'crypto-js';

// Types
interface Storage {
  length: number;
  clear(): void;
  key(index: number): string | null;
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
}

const APP_ENVIRONMENT: string = import.meta.env.VITE_ENVIRONMENT!;
const STORAGE_SECRET_KEY: string = import.meta.env.VITE_STORAGE_SECRET_KEY!;
const STUDIO_ORGANIZATIONS: string = import.meta.env.VITE_STUDIO_ORGANIZATIONS!;

function getStorageType(isLocal = false): Storage | null {
  try {
    let storage = window.sessionStorage;

    if (isLocal) {
      storage = window.localStorage;
    }

    return storage;
  } catch {
    console.warn('This site will not work with your current browser settings');

    return null;
  }
}

function encryptValue(value: string): string {
  return AES.encrypt(value, STORAGE_SECRET_KEY).toString();
}

function decryptValue(value: string): string {
  const bytes = AES.decrypt(value, STORAGE_SECRET_KEY);

  return bytes.toString(enc.Utf8);
}

export const set = ({ key, value, encrypt = true, isLocal = false }: { key: string; value: string; encrypt?: boolean; isLocal?: boolean }): void => {
  if (APP_ENVIRONMENT === 'local' || APP_ENVIRONMENT === 'development') encrypt = false;

  try {
    getStorageType(isLocal)?.setItem(key, encrypt ? encryptValue(value) : value);
  } catch (error) {
    console.error(error);
  }
};

export const get = ({ key, decrypt = true, isLocal = false }: { key: string; decrypt?: boolean; isLocal?: boolean }): string | null | void => {
  if (APP_ENVIRONMENT === 'local' || APP_ENVIRONMENT === 'development') decrypt = false;

  try {
    const storageValue: string = getStorageType(isLocal)?.getItem(key) || '';

    return decrypt ? decryptValue(storageValue) : storageValue;
  } catch (error) {
    console.error(error);
  }
};

export const remove = ({ key, isLocal = false }: { key: string; isLocal: boolean }): void => {
  try {
    getStorageType(isLocal)?.removeItem(key);
  } catch (error) {
    console.error(error);
  }
};

export const clearAll = (): void => {
  try {
    // Clear all except the STUDIO_ORGANIZATIONS storage
    const localKeys = Object.keys(localStorage);
    const sessionKeys = Object.keys(sessionStorage);

    localKeys.forEach((key) => {
      if (key !== STUDIO_ORGANIZATIONS) remove({ key, isLocal: true });
    });

    sessionKeys.forEach((key) => {
      if (key !== STUDIO_ORGANIZATIONS) remove({ key, isLocal: false });
    });
  } catch (error) {
    console.error(error);
  }
};
