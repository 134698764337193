import { useRef, useEffect, forwardRef } from 'react';

// Plugins
import { animate } from 'motion';
import Tippy from '@tippyjs/react';

// Helpers
import colors from '../../../../theme-colors';

// Styles
import 'tippy.js/dist/tippy.css';

// Types
import { ButtonColors } from 'types';

interface ButtonProps {
  className?: string;
  labelClassName?: string;
  name: string;
  form?: string;
  type?: 'submit' | 'reset' | 'button';
  color?: ButtonColors;
  label?: number;
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  textSize?: 'small' | 'medium' | 'large';
  outline?: boolean;
  toolTip?: string;
  style?: React.CSSProperties;
  dataAttr?: string;
  dataCy?: string;

  active?: boolean;
  loading?: boolean;
  disabled?: boolean;

  onClick?: (event?: any) => void;
  children?: React.ReactNode;
}

type RefButtonType = React.ForwardedRef<HTMLButtonElement>;
type RefSVGCircleType = React.ForwardedRef<SVGCircleElement>;

// Setup Tippy
Tippy.defaultProps = {
  maxWidth: 250,
  duration: [300, 300],
  theme: 'dark',
  animation: 'scale',
  delay: 300,
  touch: ['hold', 500]
};

// Constants
const loaderSize = {
  xsmall: 80,
  small: 60,
  default: 40
};

const loadingAnimation = (ref: SVGCircleElement, size: 'small' | 'default') => {
  animate(
    ref,
    {
      visibility: 'visible',
      transform: ['rotate(0deg)', 'rotate(720deg)', 'rotate(1080deg)'],
      strokeDashoffset: [0.66 * loaderSize[size], 3.14 * loaderSize[size], 0.66 * loaderSize[size]]
    },
    { duration: 3, easing: 'linear', repeat: Infinity }
  );
};

const Loader = forwardRef(({ size, color }: { size: 'xsmall' | 'small' | 'default'; color: string }, ref: RefSVGCircleType) => (
  <svg xmlns="http://www.w3.org/2000/svg" className="opacity-70" x={0} y={0} width={30} viewBox={`0, 0, ${loaderSize[size]}, ${loaderSize[size]}`}>
    <circle
      ref={ref}
      cx={loaderSize[size] / 2}
      cy={loaderSize[size] / 2}
      r="16"
      fill="transparent"
      stroke={color}
      strokeWidth="8"
      strokeLinecap="round"
      strokeDasharray={3.14 * loaderSize[size]}
      style={{ transformOrigin: `${0.5 * loaderSize[size]}px ${0.5 * loaderSize[size]}px 0` }}
    ></circle>
  </svg>
));

const {
  white: { DEFAULT: whiteDefault },
  neutral: { 400: neutral400 }
} = colors;

const Button = forwardRef(
  (
    {
      className,
      name,
      form,
      type = 'button',
      color = 'blue',
      size = 'medium',
      textSize = 'medium',
      outline = false,
      toolTip = '',
      dataAttr,
      dataCy,
      style,

      active = false,
      loading = false,
      disabled = false,

      onClick,
      children
    }: ButtonProps,
    ref: RefButtonType
  ) => {
    const loadingCircleRef = useRef<SVGCircleElement>(null);

    useEffect(() => {
      if (loading === true) {
        const loaderCircleElement = loadingCircleRef.current;

        if (loaderCircleElement) {
          loadingAnimation(loaderCircleElement, 'default');
        }
      }
    }, [loading]);

    return (
      <Tippy maxWidth={250} theme="dark" interactive={false} content={<span>{toolTip}</span>} disabled={!toolTip} touch={['hold', 500]} delay={500}>
        <button
          ref={ref}
          className={`button ${color === 'transparent' ? '' : `button--${color}`} button--${size} ${
            outline ? 'button--outline' : ''
          } button-text--${textSize} ${active ? 'button--active' : ''} ${loading ? 'button--loading' : ''} ${className || ''}`}
          name={name}
          form={form}
          type={type}
          disabled={disabled}
          data-tip={toolTip}
          data-text={dataAttr}
          data-cy={dataCy}
          style={style}
          onClick={onClick}
        >
          {loading ? (
            <div>
              {size === 'small' || size === 'xsmall' ? (
                <Loader ref={loadingCircleRef} size={size} color={color === 'white' || color === 'transparent' ? neutral400 : whiteDefault} />
              ) : (
                'Loading...'
              )}
            </div>
          ) : (
            children
          )}
        </button>
      </Tippy>
    );
  }
);

const ButtonLink = forwardRef(
  (
    {
      className,
      name,
      color = 'blue',
      size = 'medium',
      textSize = 'medium',
      loading = false,
      disabled = false,
      style,
      dataAttr,
      dataCy,
      onClick,
      children
    }: ButtonProps,
    ref: RefButtonType
  ) => {
    const loadingCircleRef = useRef<SVGCircleElement>(null);

    useEffect(() => {
      if (loading === true) {
        const loaderCircleElement = loadingCircleRef.current;

        if (loaderCircleElement) {
          loadingAnimation(loaderCircleElement, 'small');
        }
      }
    }, [loading]);

    return (
      <button
        ref={ref}
        title={name}
        className={`button-link ${color === 'transparent' ? '' : `button-link--${color}`} button-link--${size} ${className || ''} button-text--${textSize}`}
        name={name}
        type="button"
        disabled={disabled}
        style={style}
        data-text={dataAttr}
        data-cy={dataCy}
        onClick={onClick}
      >
        {loading ? <Loader ref={loadingCircleRef} size="small" color={neutral400} /> : children}
      </button>
    );
  }
);

const ButtonIcon = forwardRef(
  (
    {
      className,
      labelClassName,
      name,
      color = 'white',
      size = 'medium',
      label = 0,
      outline = false,
      loading = false,
      disabled = false,
      toolTip = '',
      dataCy,
      style,
      onClick,
      children
    }: ButtonProps,
    ref: RefButtonType
  ) => {
    const loadingCircleRef = useRef<SVGCircleElement>(null);

    useEffect(() => {
      if (loading === true) {
        const loaderCircleElement = loadingCircleRef.current;

        if (loaderCircleElement) {
          loadingAnimation(loaderCircleElement, 'small');
        }
      }
    }, [loading]);

    return (
      <Tippy content={<span>{toolTip}</span>} disabled={!toolTip}>
        <button
          ref={ref}
          className={`button ${color === 'transparent' ? '' : `button--${color}`} button-icon button-icon--${size} ${outline ? 'button-icon--outline' : ''} ${
            className || ''
          }`}
          title={name}
          name={name}
          type="button"
          disabled={disabled}
          data-tip={toolTip}
          data-cy={dataCy}
          style={style}
          onClick={onClick}
        >
          {loading ? <Loader ref={loadingCircleRef} size="small" color={color === 'white' || color === 'transparent' ? neutral400 : whiteDefault} /> : children}
          {label > 0 && <span className={`${labelClassName || ''} button-icon__label`}>{loading ? '. . .' : label}</span>}
        </button>
      </Tippy>
    );
  }
);

// Set for debugging 'Anonymous' with React DevTools
Loader.displayName = 'Loader';
Button.displayName = 'Button';
ButtonLink.displayName = 'ButtonLink';
ButtonIcon.displayName = 'ButtonIcon';

export { Button, ButtonLink, ButtonIcon };
